.allGoals {
  width: 62%;
  margin-right: 20px;
}

.allGoalsTablet {
  width: 58.5%;
  margin-right: 14px;
}

.assignedGoals {
  width: 38%;
}

.assignedGoalsTablet {
  width: 44%;
  margin-right: 0%;
}

.allGoalsContent,
.assignedGoalsContent {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.assignedGoalsContent {
  min-height: 80%;
}

.assignedGoal {
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 0 15px;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  margin: 10px 0;
}

.assignedGoal p {
  color: #000;
}

.assignedGoal img {
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.achievedGoal {
  color: green;
  display: flex;
  align-items: flex-end;
  margin-right: 10px;
  width: 10%;
}

.assignedGoalText {
  width: 100%;
}
.gridButton2 {
  display: flex;
  background-color: white;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  align-items: center;
  cursor: pointer;
  margin-left: 16px;
  padding: 20px;
  width: 350px;
}

.gridButtonTablet {
  display: flex;
  background-color: white;
  margin-left: 16px;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  align-items: center;
  cursor: pointer;
  font-size: 11px;
  padding: 15px;
}
