.pageContainer {
  margin-left: 1rem;
  height: 100%;
}

.tableContainer {
  background-color: white;
  border-radius: 4px;
}

.gridDates {
  padding-top: 45;
  padding-left: 50;
}
