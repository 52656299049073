.buttonContainer {
  position: relative;
  display: flex;
  justify-content: end;
  top: 20px;
  right: 20px;
  z-index: 999;
}

.modalQuestionnaires {
  display: flex;
  height: 400px;
  width: 800px;
  background-color: white;
  padding: 10px;
}

.questionnaireContainer {
  display: flex;
  justify-content: center;
  transform: translate(0%, 50%);
}

.containerInside,
.containerResultsInside {
  flex-direction: column;
  display: flex;
  background-color: white;
  padding: 1.5rem;
}

.containerInside {
  width: 650px;
}

.containerResultsInside {
  width: 1100px;
}

.buttons {
  display: flex;
  justify-content: end;
}

.subtitle {
  margin: 0px;
  font-size: 1.25em;
  color: #000;
}

.scoreContainer {
  margin-top: 2em;
  padding: 5px;
}

.score {
  color: white;
  background-color: #429fda;
  border-radius: 5px;
  width: 300px;
  padding: 10px;
}

.scoreText {
  padding: 10px;
  width: 500px;
}

.containerContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pendingStatus {
  color: black;
}

.completedStatus {
  color: red;
}

.buttonBack {
  display: flex;
  padding: 20px;
  column-gap: 1rem;
  justify-content: flex-end;
}

.titleBox {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 1rem !important;
}

.title {
  margin: 0px !important;
  font-weight: normal;
  font-size: 1.25rem;
  color: #000;
}
