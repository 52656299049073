.buttonContainer {
  position: relative;
  display: block;
  float: right;
  top: 40px;
  right: 20px;
  z-index: 999;
}

.tableContainer {
  position: relative;
}

.generalContainer {
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-top: 0;
  padding: 40px;
}

.buttons {
  display: flex;
  justify-content: end;
  flex-direction: row;
}

.button {
  margin-left: 20px;
}

.separator {
  margin-top: 40px;
  border-top: 1px solid #7c7c7c;
}

.countNotifications {
  margin-left: 15px;
  color: rgb(26, 150, 255);
}

.countNotificationsContainer {
  display: flex;
  flex-direction: row;
}
