.gridDates {
  padding-top: 45;
  padding-left: 50;
}

.buttonContainer {
  display: flex;
  padding: 20px;
  column-gap: 1rem;
  justify-content: flex-end;
  font-family: Open-sans, sans-serif !important;
}

.buttonContainer2 {
  display: flex;
  justify-content: flex-end;
}

.editDialog {
  width: 1000px;
  font-family: Open-sans, sans-serif !important;
}

.title h2 {
  color: #000 !important;
  font-family: Open-sans, sans-serif !important;
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #1996ff;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 5px 0px #dedcdc inset;
}
input[type='radio']:hover {
  box-shadow: 0 0 5px 0px #1996ff inset;
}
input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
input[type='radio']:checked:before {
  background: #1996ff;
}
