.canvas {
  max-height: 300px !important;
}

@media (max-width: 350px) {
  .canvas {
    width: 230px !important;
  }
}

@media (min-width: 355px) {
  .canvas {
    width: 305px !important;
  }
}

@media (min-width: 500px) {
  .canvas {
    width: 400px !important;
  }
}

@media (min-width: 600px) {
  .canvas {
    width: 500px !important;
  }
}

@media (min-width: 700px) {
  .canvas {
    margin-left: 5%;
    width: 580px !important;
  }
}

@media (min-width: 800px) {
  .canvas {
    margin-left: 9%;
    width: 800px !important;
  }
}

@media (min-width: 1024px) {
  .canvas {
    margin-left: 2%;
    width: 550px !important;
  }
}

@media (min-width: 1280px) {
  .canvas {
    width: 1000px !important;
  }
}

@media (min-width: 1400px) {
  .canvas {
    margin-top: 4%;
    margin-left: 5%;
    width: 1500px !important;
    max-height: 300px !important;
  }
}
