.buttonContainer {
  position: relative;
  display: flex;
  justify-content: end;
  top: 20px;
  right: 20px;
  z-index: 999;
  font-family: Open-sans, sans-serif !important;
}

.tableContainer {
  position: relative;
  top: -60px;
  font-family: Open-sans, sans-serif !important;
}

.lessonItem {
  border-color: #0051c2;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  width: 1700px;
  padding: 10px;
  margin-left: 10%;
  margin-right: 15%;
  background-color: white !important;
  font-family: Open-sans, sans-serif !important;
}

.lessonItemSelected {
  border-color: #39b243;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  width: 700px;
  padding: 10px;
  margin-left: 10%;
  margin-right: 15%;
  background-color: white !important;
  font-family: Open-sans, sans-serif !important;
}

.gridButtonTablet2 {
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  align-items: center;
  cursor: pointer;
  padding: 15px;
  font-size: 11px;
  margin-left: 16px;
  width: 100%;
  font-family: Open-sans, sans-serif !important;
}

.gridButton2 {
  display: flex;
  background-color: white;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  align-items: center;
  cursor: pointer;
  margin-left: 16px;
  padding: 20px;
  width: 350px;
  font-family: Open-sans, sans-serif !important;
}
