.addGoalsContainer {
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 20px;
  font-family: Open-sans, sans-serif !important;
}

.addGoalsTitle {
  padding: 0;
  margin: 0;
  font-size: 24px;
  color: #000;
  font-family: Open-sans, sans-serif !important;
}

.textField {
  width: 100%;
  margin-right: 20px !important;
  font-family: Open-sans, sans-serif !important;
}
