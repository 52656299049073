.container {
  margin-bottom: 10px;
  border-bottom: 3px solid var(--color-light-blue);
  display: flex;
  justify-content: space-between;
}

.header {
  font-weight: bold;
  font-size: 1rem;
  width: 100%;
}

.icon {
  cursor: pointer;
  width: 25px;
}
