.centerCell {
  text-align: center !important;
}

.headerCell {
  font-weight: bold !important;
  background-color: #0063a6;
  color: #ffffff !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.centerCellButton {
  text-align: center !important;
  display: block ruby !important;
}

.pointer {
  cursor: pointer;
}

.noElements {
  font-size: 3rem;
  text-align: center;
}

.buttonContainer {
  display: flex;
  column-gap: 1rem;
}

.currentElement {
  background-color: var(--color-selected-table-item);
}

.tableRow:hover,
.tableRow:active {
  background-color: var(--color-selected-table-item);
}

.genericFlexContainerSpacing {
  column-gap: 1rem;
  row-gap: 1rem;
  cursor: pointer;
}

.paginationContainer {
  width: 100%;
  align-items: center;
  display: flex;
}

.pagination {
  margin: auto;
  padding-top: 10px;
}

.loadingSpinner {
  position: absolute;
  right: 40%;
  bottom: 40%;
}
