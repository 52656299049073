.pageContainer {
  background: #ffffff;
  margin: 25px;
  box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 15px;
}

.captionContainer {
  background: #ffffff;
  margin: 25px;
  box-shadow: -1px -5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 0;
}

.roleContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.roleContainer img {
  margin-right: 15px;
}

@media (max-width: 1500px) {
  .roleContainer {
    width: 80%;
    flex-wrap: wrap;
    justify-content: left;
  }
  .role {
    margin: 10px 30px 10px 0;
  }
}

@media (max-width: 600px) {
  .pageContainer {
    margin: 10px;
  }
  .roleContainer {
    margin: 10px;
  }
  .captionContainer {
    margin: 10px;
  }
}

.formControl {
  padding-bottom: 5px;
}
