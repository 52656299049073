@font-face {
  font-family: 'Poppins-Regular';
  src: url('../../assets/typographies/Poppins/Poppins-Light.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/typographies/Roboto/Roboto-Regular.ttf') format('truetype');
  font-style: normal;
}
.gridPatient {
  display: flex;
  background-color: white;
  height: 400px;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  padding: 35px 0 !important;
}

.gridPatientO {
  display: flex;
  background-color: white;
  height: 400px;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  padding: 15px 35px !important;
}

.aux {
  height: 400px;
  width: 400px;
  overflow-y: scroll;
}

.gridPatient2 {
  background-color: white;
  height: 18rem;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  padding: 5px;
}

.gridMessaging {
  height: 100%;
}

.generalDataH3 {
  font-family: Open-sans, sans-serif !important;
  color: #000;
}

.gridMessaging h3 {
  font-family: Open-sans, sans-serif !important;
}

.messagingBox {
  height: 18rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
}

.conversationWidget {
  background-color: #ffffff !important;
  padding: 20px !important;
}

.searcherContainer {
  column-gap: 1rem;
}

.blueLine {
  padding-top: 20px;
  margin-bottom: 20px;
  border-bottom: 3px solid var(--color-light-blue);
}

.boxMessage {
  width: 100%;
}

.messengerContainer {
  display: flex;
  column-gap: 1rem;
}

.patientBox {
  background-color: white;
  border-radius: 12px;
  padding: 5px;
  box-shadow: none;
}

.patientBox2 {
  justify-content: flex-start;
}

.patientBox3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.patientAvatar {
  display: flex;
  justify-content: center;
  width: 25%;
  height: 100%;
}

.boxPatientDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 14px 25px;
  width: 100%;
}

.patientData {
  display: flex;
  flex-direction: column;
  width: 83%;
  height: 100%;
  margin-left: 40px;
  margin-top: 10px;
}

h2 {
  margin-top: 0 !important;
}

.gridButton {
  display: flex;
  background-color: white;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  align-items: center;
  cursor: pointer;
  padding: 20px;
  /*max-width: 99%;*/
}

.gridButton2 {
  display: flex;
  background-color: white;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  align-items: center;
  cursor: pointer;
  padding: 20px;
}

.gridButtonTablet {
  display: flex;
  background-color: white;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  align-items: center;
  cursor: pointer;
  font-size: 11px;
  padding: 20px;
}

.gridButtonTablet3 {
  display: flex;
  background-color: white;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  align-items: center;
  cursor: pointer;
  padding: 20px;
  font-size: 12px;
  width: 100%;
}

.gridButtonTablet2 {
  display: flex;
  background-color: white;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  align-items: center;
  cursor: pointer;
  padding: 20px;
  font-size: 11px;
  width: 100%;
}

.documentsButton {
  cursor: pointer;
}

.documentsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.documents {
  display: flex;
  flex-direction: row;
  border-bottom: solid 1px #a8a8a8;
  align-items: center;
}

.documentsLast {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.documentsLast h4 {
  margin: 10px 0 0 10px;
  padding: 0;
}

.documents h4 {
  margin: 10px 0 0 10px;
  padding: 0;
}

.documentsSeparator {
  display: flex;
  justify-content: center;
  width: 80px;
  border-right: 1px solid #a8a8a8;
  margin-left: 5px;
  margin-right: 5px;
  height: 40px;
}

.documentsSeparator2 {
  display: flex;
  justify-content: center;
  width: 80px;
  border-left: 1px solid #a8a8a8;
}

.documentsButton {
  width: 90px;
  background-color: #1796ff !important;
  text-transform: none !important;
  border-radius: 4px !important;
  font-size: 20px !important;
  margin: 10px !important;
}

.contentEducational {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.contentEducationalTablet {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.statusEducational {
  width: 30%;
  border-radius: 5px;
  margin-left: 18px;
}

.educationalElement {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.statusTextPending,
.statusTextCompleted {
  border-radius: 10px;
  width: 100px;
  padding: 4px 8px;
}

.statusTextPending {
  background-color: #ffe7d7;
  color: #ff8200;
}

.statusTextPendingTablet {
  width: 100%;
  border-radius: 10px;
  padding: 3px;
  background-color: #ffe7d7;
  color: #ff8200;
}

.statusTextCompletedTablet {
  border-radius: 10px;
  padding: 3px;
  background-color: #dbefdc;
  color: #3daa32;
  width: 100%;
}

.statusTextCompleted {
  background-color: #dbefdc;
  color: #3daa32;
}

.statusEducational {
  text-align: center;
}

.statusEducationalTablet {
  text-align: center;
}

.goalsContainer {
  display: flex;
  padding: 20px 0;
  flex-direction: column;
  width: 100%;
}

.goalElem {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
  padding: 0 2px 18px 2px;
}

.goalElemTablet {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
  padding: 0 2px 18px 2px;
  font-size: 12.5px;
}

.educationalBar {
  margin-top: 10px;
  width: 80%;
}

.statusEducational {
  justify-content: flex-end;
  transform: translate(0%, 50%);
}

.progressPending,
.progresStarted,
.progressFinish {
  height: 10px;
  border-radius: 5px;
}

.messengerWidget {
  width: auto !important;
}

.author {
  font-size: 0.9rem;
  margin-bottom: 0;
  color: var(--color-dark-blue);
}

.messagesContainer {
  min-height: 40vh;
  max-height: 40vh;
}

.headerContainer {
  background-color: #375980 !important;
}

.messageContainer {
  padding: 1rem;
  background-color: var(--color-white);
}

.divMessagesScroll {
  display: flex;
  flex-direction: column;
  min-height: inherit;
  max-height: inherit;
  overflow-y: auto;
  scroll-behavior: smooth;
  background-color: var(--color-white);
}

.divMessagesScrollEmpty {
  justify-content: center;
  align-items: center;
}

.progressPending {
  background-color: #d8dae4;
}

.progresStarted {
  background: linear-gradient(90deg, #50acf9 50%, #d8dae4 50%);
}

.progressFinish {
  background: #50acf9;
}

.newMessageField {
  flex-direction: row;
  background-color: #c1e3ff;
  height: 50px;
  width: 100%;
  align-self: flex-end;
  margin: 10px;
  border-radius: 25px;
  padding-left: 15px;
  padding-top: 10px;
}

.newMessageField {
  border: 0 !important;
}

.messageEntry {
  width: 90%;
  border: 0 !important;
}

.messageFieldset {
  border: 0 !important;
}

.patientDetail {
  color: #50acf9;
  text-align: center;
}

.patientDetailTablet {
  color: #50acf9;
  font-size: 12px;
}

.patientDetail {
  color: #50acf9;
  width: 100%;
  font-size: 14px;
}

.patientDetail:hover {
  cursor: pointer;
}

.buttonBack {
  display: flex;
  padding: 20px;
  column-gap: 1rem;
  justify-content: flex-end;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}
