.userProfileContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.leftContainer {
  width: 250px;
  height: 280px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.removeAccount {
  display: flex;
  margin-top: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.avatarContainer {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.avatarContainer h5 {
  margin: 20px 0 0 0;
  font-size: 15px;
}

.avatarContainer h6 {
  margin: 5px 0 0 0;
  font-size: 10px;
}

.rightContainer {
  background-color: white;
  border-radius: 5px;
  width: 100%;
  margin-bottom: 8px;
}

.profileContainer {
  display: flex;
  flex-direction: column;
}

.profileHeader {
  border-bottom: 2px solid #d0d0d0;
}

.profileContainer p {
  margin-left: 20px;
  font-weight: bold;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.buttonContainer {
  display: flex;
  padding: 20px;
  column-gap: 1rem;
  justify-content: flex-end;
}

.headerContainer {
  margin-bottom: 30px;
}

.textField {
  margin-right: 20px !important;
  color: #000 !important;
  width: 300px;
}

.doubleRow {
  width: 487px !important;
}

.doubleRowNotes {
  max-width: 800px !important;
  width: 800px !important;
}

.doubleRowNotesTablet {
  max-width: 570px !important;
  width: 570px !important;
}

.roleIcon {
  padding-right: 10px;
  width: 25px;
}

.select {
  width: 400px;
  height: 40px;
}

.hiddenId {
  display: none;
}

@media (max-width: 1600px) {
  .textFieldBox {
    flex-direction: column;
    width: auto;
  }
  .select {
    width: auto;
  }
  .doubleRow {
    width: auto;
  }
}
