.background {
  background-size: 100% 100% !important;
  height: 100vh;
  width: 100%;
  background-color: #ebebeb;
}

.mainContainer {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  height: 100vh;
  width: 100%;
  flex-direction: column;
}

.mainContainer h1 {
  color: white;
  font-family: Open-sans, sans-serif !important;
  font-size: 40px;
}

.mainContainer h5 {
  color: #9f3a38;
  font-size: 10px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  font-family: Open-sans, sans-serif !important;
}

.boxPricacy {
  width: '110%';
  background-color: 'white';
  height: '78%';
  border-radius: 40;
}

.logo {
  width: 360px;
  padding: 0;
  background-color: white;
  border-radius: 5px;
  /*box-shadow: 4px 3px 13px -1px #757575;*/
}

.logoTablet {
  width: 315px;
  padding: 0;
  margin-right: 50px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 4px 3px 13px -1px #757575;
}

.logoValidated {
  width: 200px;
  padding: 0;
  margin-right: 50px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 4px 3px 13px -1px #757575;
}

.epico {
  width: 120px;
  margin: 10px 0 30px 0;
}

.formContainer {
  background: white;
  padding: 35px 30px 35px 30px;
  border-radius: 4px;
  width: 350px;
  box-shadow: 4px 6px 29px -20px #757575;
}

.boxInput {
  border: 1px solid #000000 !important;
  border-radius: 40px;
  padding: 4px;
  height: 40px;
}

.boxInputSearchWithIcon {
  border: 1px solid #dddddd !important;
  border-radius: 7px;
  padding: 4px;
  height: 40px;
}

.formContainer h2 {
  color: #253d59;
  text-align: center;
  margin: 20px 0 10px 0;
}

.forgottenPasswordText {
  color: #000001;
  font-weight: normal;
  cursor: pointer;
  margin-top: 20px;
  font-size: 12px;
  align-self: center;
}

.links {
  color: #797a7a;
  font-weight: normal;
  cursor: pointer;
  margin-top: -5px;
  font-size: 12px;
  align-self: center;
  margin-left: 3px;
  font-family: Open-sans, sans-serif !important;
}
.footer {
  display: flex;
  align-self: flex-end;
  width: 100%;
  margin-top: auto;
}
.button {
  margin: 20px 0 10px 0;
}

.modalPassword {
  margin: 20px !important;
}

.fieldsContainer {
  display: flex;
  width: auto;
  flex-direction: column;
  padding: 50px;
}

.textFieldBox {
  flex-direction: column;
  width: auto;
  max-width: 500px;
}

.textField {
  width: auto;
  margin-right: 35px !important;
}

.selectField {
  width: 240px;
  margin-right: 20px;
  min-height: 40px;
  height: 40px;
}

.buttonContainer {
  display: flex;
  padding: 20px;
  column-gap: 1rem;
  justify-content: flex-end;
}
