.currentNavHeaderContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 4rem;
}

.currentNavHeaderContainer > * {
  margin: 0;
}

.titleContainer {
  border-bottom-left-radius: 25px;
}

h1 {
  font-weight: bold;
  font-family: Open-sans, sans-serif !important;
  color: #000001;
  font-size: 24px;
  margin-left: 1rem;
}
