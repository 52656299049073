.emailBox {
  margin-top: 2.5%;
  margin-left: 1.5%;
  width: 170%;
}

.participantBox {
  margin-left: 1.5%;
  margin-top: 3%;
}

@media (max-width: 480px) {
  .emailBox {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .participantBox {
    width: 300%;
  }
}

@media (max-width: 1024px) {
  .emailBox {
    width: 80%;
  }
}

@media (max-width: 1024px) {
  .participantBox {
    width: 260%;
  }
}

@media (min-width: 1025px) {
  .participantBox {
    width: 200%;
  }
}

@media (min-width: 1025px) {
  .emailBox {
    width: 75%;
  }
}
