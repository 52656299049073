@font-face {
  font-family: 'Open-sans';
  src: url('./assets/typographies/Open_Sans/static/OpenSans/OpenSans-Regular.ttf')
    format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Open-sans-bold';
  src: url('./assets/typographies/Open_Sans/static/OpenSans/OpenSans-Bold.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Gotham';
  src: url('./assets/typographies/gotham-rounded/GothamRounded-Medium.ttf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Gotham-book';
  src: url('./assets/typographies/gotham-rounded/GothamRoundedBook_21018.ttf') format('truetype');
  font-style: normal;
}

html,
body,
#root {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-family: Open-sans, sans-serif !important;
}
button {
  font-family: Open-sans, sans-serif !important;
}
