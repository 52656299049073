@font-face {
  font-family: 'Poppins-Black';
  src: url('../../assets/typographies/Poppins/Poppins-Medium.ttf') format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('../../assets/typographies/Roboto/Roboto-Medium.ttf') format('truetype');
  font-style: normal;
}
.button {
  font-size: 14px !important;
  text-transform: none !important;
  height: 30px !important;
  font-family: Open-sans, sans-serif !important;
}

.newPrimary {
  background-color: rgb(1, 58, 196) !important;
  font-family: Open-sans, sans-serif !important;
}

.newPrimaryLight {
  background-color: rgb(26, 150, 255) !important;
  height: 34px !important;
  width: 120px !important;
  font-family: Open-sans, sans-serif !important;
  font-weight: bold !important;
}
.newPrimaryLightWithoutWidth {
  background-color: rgb(26, 150, 255) !important;
  height: 34px !important;
  font-family: Open-sans, sans-serif !important;
  font-weight: bold !important;
}

.newSecondary {
  background-color: #ffffff !important;
  border: 1px solid rgb(26, 150, 255) !important;
  height: 34px !important;
  color: rgb(26, 150, 255) !important;
  font-family: Open-sans, sans-serif !important;
}

.transparent {
  background-color: #ffffff !important;
  border: none;
  font-family: Open-sans, sans-serif !important;
}

.transparent2 {
  background-color: #ffffff !important;
  color: rgb(26, 150, 255) !important;
  height: 34px !important;
  width: 120px !important;
  border: 1px solid rgb(26, 150, 255) !important;
  font-family: Open-sans, sans-serif !important;
  font-weight: bold !important;
}

.newPrimaryResponsive {
  background-color: #68b3e0 !important;
  font-family: Open-sans, sans-serif !important;
}

@media (max-width: 480px) {
  .newPrimaryResponsive {
    width: 33%;
  }
}

@media (min-width: 1024px) {
  .newPrimaryResponsive {
    width: 25%;
  }
}

@media (min-width: 1440px) {
  .newPrimaryResponsive {
    width: 12%;
  }
}
