.buttonContainer {
  position: relative;
  display: flex;
  justify-content: end;
  top: 20px;
  right: 20px;
  z-index: 999;
}

.textField {
  width: auto;
  margin-right: 20px !important;
}

.fieldsContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.selectField {
  width: 206px;
  margin-right: 20px;
  min-height: 40px;
  height: 40px;
}

.buttonContainer {
  display: flex;
  padding: 20px;
  column-gap: 1rem;
  justify-content: flex-end;
}

@media (max-width: 1600px) {
  .textFieldBox {
    flex-direction: column;
    width: auto;
    max-width: 500px;
    color: #000;
  }
}
