.background {
  background-size: 100% 100% !important;
  height: 100vh;
  width: 100%;
  background-color: #2476a8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  min-width: 275;
  max-height: 500;
  border: none;
  margin-top: -12%;
  background-color: white;
  border-radius: 5%;
  font-family: Open-sans, sans-serif !important;
}

.titleCard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: Open-sans, sans-serif !important;
}

.titleText {
  color: black;
  font-weight: bold;
  align-self: center;
  font-family: Open-sans, sans-serif !important;
}

.boxButton {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin-top: 9%;
  padding-bottom: 3%;
  font-family: Open-sans, sans-serif !important;
}

.componentStyle {
  display: flex;
  justify-content: center;
  margin-top: 6%;
  font-family: Open-sans, sans-serif !important;
}

.authenticateButton {
  background-color: #429fda;
  width: 68%;
  align-self: center;
  font-family: Open-sans, sans-serif !important;
}
