.lessonContainer {
  border-color: #0050c4;
  background-color: white;
  width: 100%;
  padding: 10px;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
}

.lessonItem {
  border-color: #0050c4;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  width: 100%;
  padding: 10px;
  margin-left: 10%;
  margin-right: 15%;
  background-color: white !important;
}
