.firstGrid {
  background-color: #429fda;
  display: flex;
  justify-content: center;
  align-content: center;
}

.filtersContainer {
  background-color: white;
  border-radius: 4px;
  margin-bottom: 20px;
}

.titleContainer {
  display: flex;
  font-family: Open-sans, sans-serif !important;
}

.titleContainer h4 {
  margin: 10px 0 10px 20px;
  font-family: Open-sans, sans-serif !important;
}

.filtersDetailContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  font-family: Open-sans, sans-serif !important;
}

.tabContainer {
  background-color: white;
  border-radius: 4px;
  font-family: Open-sans, sans-serif !important;
}

.tabContainer .MuiTabs-indicator {
  display: none !important;
  font-family: Open-sans, sans-serif !important;
}

.tabContainer Button {
  /* min-height: 40px !important;  change the heights of tabs*/
  font-size: 15px !important;
  text-transform: none !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-family: Open-sans, sans-serif !important;
}

.parsetabs {
  background-color: #f2f1f1 !important;
  color: #8f8f8f !important;
  width: 180px !important;
  height: 40px !important;
  margin-bottom: 0 !important;
  font-weight: bold !important;
  font-family: Open-sans, sans-serif !important;
}

.parsetabsActive {
  background-color: #ffffff !important;
  color: black !important;
  width: 170px !important;
  height: 30px !important;
  font-weight: bold !important;
  font-family: Open-sans, sans-serif !important;
}

.caltabs {
  background-color: #eaeaea !important;
  box-shadow: none !important;
  font-family: Open-sans, sans-serif !important;
}

.parsetabs > div > span {
  font-weight: bold !important;
  font-family: Open-sans, sans-serif !important;
}

.caltabs > div > span {
  top: 0;
  background-color: #fff;
  height: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 8px;
  font-weight: bold !important;
  font-family: Open-sans, sans-serif !important;
}

.selectTitle {
  margin: 0;
}

.secondRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.buttonsContainer {
  position: relative;
  display: block;
  float: right;
  top: 64px;
  right: 20px;
  z-index: 999;
  font-family: Open-sans, sans-serif !important;
}

.buttonContainer {
  margin: 0 20px 0 0;
  font-family: Open-sans, sans-serif !important;
}

.chartsContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 0;
  font-family: Open-sans, sans-serif !important;
}

.chartsContent {
  display: flex;
  justify-content: center;
  margin-top: 0;
  font-family: Open-sans, sans-serif !important;
}

@media (max-width: 1250px) {
  .secondRow {
    flex-direction: column;
  }

  .buttonsContainer {
    justify-content: end;
  }
}

.buttonBack {
  position: relative;
  display: flex;
  justify-content: end;
  top: 20px;
  right: 20px;
  z-index: 999;
}

.title {
  margin: 0 !important;
  font-weight: normal;
  font-size: 1.25rem;
  color: #000;
}

.titleBox {
  padding-top: 10px;
  margin-left: 1rem;
}
