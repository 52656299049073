.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border-color: #bfdff2;
  border: 1px solid #dddddd;
}

.td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

.tr {
  background-color: #bfdff2;
}
