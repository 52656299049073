.loadingSpinner {
  position: absolute;
  right: 40%;
  bottom: 30%;
}

.loadingSpinnerCalendar {
  position: absolute;
  right: 35%;
  bottom: 35%;
}
