::-webkit-scrollbar {
  width: 18.7px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #b8b8b8;
}

::-webkit-scrollbar-thumb {
  background-color: #b8b8b8;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #b8b8b8;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  background-color: rgba(var(--color-gray-RGB), 0.1);
  min-height: calc(100vh - 80px);
}

.contentContainer {
  flex: 1;
  overflow-y: auto;
}

.footer {
}

/* TO AVOID SET PADDING IN ALL FEATURES WITHOUT NEED TO PUT CSS IN ROUTER */
/* IT'S SET PADDING IN MAIN CONTAINER CHILD */
.mainContainer > * > * {
  padding: 1.5rem 1.5rem 0;
}

.gridContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.gridPatients {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
}

.gridPatients h2 {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 0;
  color: #000001;
}

.gridNumbers {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  align-items: center;
  padding: 25px;
}

.gridNumbersNewPatient {
  display: flex;
  flex-direction: column;
  background-color: #2b55b4;
  height: 9.4rem;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  justify-content: left;
  align-items: center;
  padding: 20px;
}

.noticePanelGrid {
  background-color: white;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-left: 0.5%;
  font-family: Open-sans, sans-serif !important;
}

.noticePanelGrid h2 {
  color: #000001;
}

.noticePanelGrid2 {
  background-color: white;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2px;
}

.gridNumbers h1 {
  margin: 0 0 0 10px;
}

.gridNumbers h4 {
  margin: 0 0 0 10px;
}

.tableContainer {
}

.gridNewPatient {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 16rem;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  padding: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.gridNewPatientBox {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 255px;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  padding: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.gridNewPatientGrid {
  max-height: 10rem;
}

.gridCalendar {
  display: flex;
  background-color: white;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
  padding: 5px;
}

.gridNews {
  display: flex;
  background-color: white;
  height: 32rem;
  border-radius: 12px;
  box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);
}

.hrStyle {
  border: 1.6px solid #41e07e;
  background-color: #41e07e;
}

.selectStyle {
  margin-top: 6.5%;
  margin-left: 50%;
}

.weekDays {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.currentDate {
  display: flex;
  flex-direction: column;
}

.calendarContainer {
  border-top: 1px solid #e6ecef;
  width: 100%;
}

.calendarPercentage {
  height: 75%;
}

.calendarGeneral {
}

.calendarDetail {
  border: none !important;
}

.marked {
  background: #ffdc87 !important;
}

.percentageContainer {
  width: 200px;
  height: 200px;
  margin-left: 60px;
}
