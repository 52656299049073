.noticePanelGrid {
  background-color: #f5f5f4;
  border-radius: 5px;
  /*box-shadow: 4px 3px 13px -1px rgba(117, 117, 117, 0.27);*/
  justify-content: left;
  align-items: center;
  padding: 20px;
  margin-left: 0.5%;
  margin-bottom: 1%;
  cursor: pointer;
}

.pStyle {
  margin-left: 13.5;
  font-size: 15;
  padding: 3;
  font-weight: bold;
}
