@font-face {
  font-family: 'Poppins-Regular';
  src: url('../../assets/typographies/Poppins/Poppins-Light.ttf') format('truetype');
  font-style: normal;
}
.header {
  width: 100%;
  height: 40px;
  background-color: #1a96ff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottomHeader {
  width: 100%;
  height: 40px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left {
  margin-left: 20px;
  font-family: Open-sans, sans-serif !important;
  align-items: center;
}

.center {
  margin-left: 20px;
  font-family: Open-sans, sans-serif !important;
  align-items: center;
}

.center h5 {
  font-family: Open-sans, sans-serif !important;
}

.right {
  font-family: Open-sans, sans-serif !important;
  align-items: center;
  margin-right: 2%;
}

.center h6 {
  margin: 0 20px 0 20px;
  color: white;
  cursor: pointer;
  font-size: 0.9em;
}

.left h2 {
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.bottomHeader h4 {
  font-size: 12px;
  margin-right: 15px;
  color: #9d9d9d;
  font-weight: lighter;
}

.title {
  color: #253d59 !important;
  font-weight: bold !important;
}

.avatar {
  width: 150px !important;
  height: 150px !important;
  cursor: pointer;
}

.avatarNavigator {
  width: 25px !important;
  height: 25px !important;
  margin-left: 20px;
  cursor: pointer;
}

.bellIcon {
  cursor: pointer;
  color: white;
}

.helpModalBox {
  background-color: white;
  width: 27%;
  height: 47%;
  border-radius: 6%;
}

.helpModalBoxTablet {
  background-color: white;
  width: 40%;
  height: 36%;
  border-radius: 14%;
  margin-top: -12%;
}

.helpModal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10%;
}

.helpModalBox2 {
  display: flex;
  justify-content: center;
  margin-top: 4%;
}

.modalText {
  margin-left: 10%;
  margin-top: 2%;
  font-family: Open-sans, sans-serif !important;
}

.modalButtons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 6%;
}
