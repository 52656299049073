.articleWrapper {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  display: flex block;
  flex-direction: column;
  row-gap: 1rem;
}

.title {
  font-size: 2.5rem;
  text-align: center;
  border-bottom: 1px solid darkgray;
  padding-bottom: 1.2rem;
  overflow: hidden;
}

.tagLink {
  padding: 0 0.5rem;
}

.tags {
  font-size: 1rem;
  border-bottom: 1px solid darkgray;
  padding-bottom: 2rem;
}

.content {
  text-align: justify;
  font-size: 1.2rem;
}

.files {
  border-top: 1px solid darkgray;
}

.backButton {
  justify-content: flex-start;
}
